// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    
}
/* .th{
    background-image: linear-gradient(45deg, rgb(20, 222, 124) 0%, rgb(20, 222, 124) 10%,rgb(25, 190, 119) 10%, rgb(25, 190, 119) 26.1%,rgb(30, 158, 113) 26.1%, rgb(30, 158, 113) 56%,rgb(35, 126, 108) 56%, rgb(35, 126, 108) 78%,rgb(39, 94, 103) 78%, rgb(39, 94, 103) 59%,rgb(44, 62, 97) 59%, rgb(44, 62, 97) 77%,rgb(49, 30, 92) 77%, rgb(49, 30, 92) 100%);
    background-color: #2C3E61 !important;
    color: white !important;
    border-radius: 0px !important;
}
.thJ{
    background-color: #1c2541 !important;
    color: white !important;
} */
 body{
    background-color: #311E5C;
 }
.Aug{
    background-color: white;
    color: black !important;
    height: 50px;
    border-radius: 12px;
    padding: 2px 10px;
}
.thc{
    background-color: #311E5C;
    color: white !important;
}
.flex{
    width: 35%;
    display: flex;
    justify-content: space-between;
}
/* .title{
    text-align: center;
    text-wrap: wrap;
} */`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,qCAAqC;;AAEzC;AACA;;;;;;;;;GASG;CACF;IACG,yBAAyB;CAC5B;AACD;IACI,uBAAuB;IACvB,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B;AACA;IACI,UAAU;IACV,aAAa;IACb,8BAA8B;AAClC;AACA;;;GAGG","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');\n*{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: \"Montserrat\", sans-serif;\n    \n}\n/* .th{\n    background-image: linear-gradient(45deg, rgb(20, 222, 124) 0%, rgb(20, 222, 124) 10%,rgb(25, 190, 119) 10%, rgb(25, 190, 119) 26.1%,rgb(30, 158, 113) 26.1%, rgb(30, 158, 113) 56%,rgb(35, 126, 108) 56%, rgb(35, 126, 108) 78%,rgb(39, 94, 103) 78%, rgb(39, 94, 103) 59%,rgb(44, 62, 97) 59%, rgb(44, 62, 97) 77%,rgb(49, 30, 92) 77%, rgb(49, 30, 92) 100%);\n    background-color: #2C3E61 !important;\n    color: white !important;\n    border-radius: 0px !important;\n}\n.thJ{\n    background-color: #1c2541 !important;\n    color: white !important;\n} */\n body{\n    background-color: #311E5C;\n }\n.Aug{\n    background-color: white;\n    color: black !important;\n    height: 50px;\n    border-radius: 12px;\n    padding: 2px 10px;\n}\n.thc{\n    background-color: #311E5C;\n    color: white !important;\n}\n.flex{\n    width: 35%;\n    display: flex;\n    justify-content: space-between;\n}\n/* .title{\n    text-align: center;\n    text-wrap: wrap;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
